<template>
  <a-modal title="关联分类" width='50%' :visible="visible" :confirmLoading="confirmLoading" :destroyOnClose="true"
    @ok="handleSubmit" @cancel="handleCancel">
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 父级 -->
            <a-form-item label="直播分类" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-select mode="multiple" show-search
                v-decorator="['broadcastCategoryIds', { rules: [{ required: true, message: '请输入关键字' }] }]" placeholder="请输入关键字"
                :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                :not-found-content="null" @search="handleSearch">
                <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id">{{ item.text }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { findLiveClassList, addBroadcastGoodsCategory } from "@/api/modular/mallLiving/liveClass"
import {uploadFile} from "@/api/modular/mallLiving/uploadFile";

export default {
  data() {
    return {
      loading: false,
      imageUrl: '',
      picture: '',
      allList: [],
      pictureLoading: false,
      labelCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 6
        },
      },
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        },
      },
      labelCol_1: {
        xs: {
          span: 24
        },
        sm: {
          span: 3
        },
      },
      wrapperCol_1: {
        xs: {
          span: 24
        },
        sm: {
          span: 20
        },
      },
      cityList: [], //市区列表
      parentList: [], //父级列表
      sort: '',
      visible: false,
      confirmLoading: false,
      cos: null,
      form: this.$form.createForm(this),
      goodsCategoryId: '',
      categoryList: []
    }
  },
  methods: {
    // 初始化方法
    async add(categoryId) {
      this.goodsCategoryId = categoryId
      this.visible = true
    },
    //搜索框搜索城市
    handleSearch(value) {
      console.log(value)
      if (value) {
        findLiveClassList({keyword: value}).then(res =>{
          let list = res.data
          list.forEach(citem =>{
            citem.text = citem.province+"_"+citem.city+'_'+citem.name
          })
          this.categoryList = JSON.parse(JSON.stringify(list))
        })
      } else {
        this.categoryList = []
      }
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          values.id = this.goodsCategoryId
          addBroadcastGoodsCategory(values).then((res) => {
            if (res.success) {
              this.$message.success("关联成功")
              this.$emit("ok", values)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    //获取初始全部列表数据
    getInfo() {
      return allPageList({}).then((res) => {
        this.allList = res.data
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.provinceName = undefined //重置省份名称
      this.confirmLoading = false
      this.pictureLoading = false
      this.form.resetFields() //重置表单
    },
    //自定义图片上传实现
    customUserRequest(filedata) {
      let that = this;
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          const percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          const list = res.res.requestUrls[0].split('?');
          if (list.length > 0) {
            this.picture = list[0]
            this.pictureLoading = false
          }
        }
      })
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
