//直播分类接口
import { axios } from '@/utils/request'
/*
 * @Description: 
 * @Version: 
 * @Author: 彭勃
 * @Date: 2022-02-8 11:15:50
 */

//直播分类分页查询
export function liveClassPageList(parameter){
    return axios({
        url: '/broadcastCategory/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}
//直播分类新增
export function liveClassAdd(parameter){
    return axios({
        url: '/broadcastCategory/add',
        method: 'post',
        data: parameter 
    })
}
//直播分类编辑
export function liveClassEdit(parameter){
    return axios({
        url: '/broadcastCategory/edit',
        method: 'post',
        data: parameter
    })
}
//直播分类详情
export function liveClassDetail(parameter){
    return axios({
        url: '/broadcastCategory/detail',
        method: 'post',
        params: parameter
    })
}
//直播分类修改状态（启用 禁用 删除）
export function liveClassDelete(parameter){
    return axios({
        url: '/broadcastCategory/delete',
        method: 'post',
        data: parameter
    })
}
// //直播分类list集合
// export function liveClassList(parameter){
//     return axios({
//         url: '/videoCategory/list',
//         method: 'post',
//         data: parameter
//     })
// }

/**
* 模块名:
* 代码描述: 直播分类列表查询
* 作者:陈莉莉
* 创建时间:2022-07-19 17:14:48
*/
export function findLiveClassList(parameter){
    return axios({
        url: "/broadcastCategory/list",
        method: "POST",
        data: parameter
    })
}

/**
* 模块名:
* 代码描述: 关联直播和商品分类
* 作者:陈莉莉
* 创建时间:2022-07-19 17:25:35
*/
export function addBroadcastGoodsCategory(parameter){
    return axios({
        url: "/broadcastGoodsCategory/addBroadcastGoodsCategory",
        method: "POST",
        data: parameter
    })
}